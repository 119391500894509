body {
padding:0;
margin:0;
box-sizing: border-box;
background:black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
