
@import url('https://fonts.googleapis.com/css2?family=Kalnia+Glaze:wght@100..700&family=Playwrite+BE+VLG&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sevillana&display=swap');
body{
  background:black;
}
.navbar input[type="checkbox"],
.navbar .hamburger-lines{
    display: none;
}

.container-fluid{
    width: 100%;
    margin: auto;
    background:transparent;
}

.navbar{
    width: 100%;
    background:transparent;
    padding-left:160px;
    padding-right:160px;
    position: fixed;
    top:0;
    left:0;z-index: 10000;
    transition: all 0.3s ease;
}
.affix{
  background: black;
}
.affix .navbar-container{
  border:none;
}
  .navbar-container{padding-top:20px;
  padding-bottom:10px;
  border-bottom:1px solid #414141;
  display:flex;
  align-items: center;
}
.navbar-container{
    padding-top:20px;
    padding-bottom:10px;
    border-bottom:1px solid #414141;
    display:flex;
    align-items: center;
}

.menu-items{
    order: 2;
    display: flex;
    align-items: center;
    gap:30px;
}

.menu-items li{
    list-style: none;
    margin-left: 1.5rem;
    font-family: 'Poppins', sans-serif;
    margin-top:20px;
    font-size:16px;
}

.navbar a{
  color:white;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    font-size:16px;
}
.Login_section_button{
  background:#AF864E;
  color:white;
  padding-top:7px;
  padding-bottom:7px;
  padding-left:16px;
  padding-right:15px;
  font-family: 'Poppins', sans-serif;
  border:none;
  border-radius:8px;
  margin-top:20px;
}

/* Home section New coding start */
.Home_banner_section{
  background:url('../../../public/Asstes/Images/BG_Banner.png');
  background-size:cover;
  background-repeat: no-repeat;
  padding-left:160px;
  padding-right:160px;
  
}
.Home_content_section{
  padding-bottom:100px;
  padding:0;
  margin:0;
}
.Home_content_section h1{
  padding:0;
  margin:0;
  padding-top:286px;
  padding-bottom:27px;
  font-family: 'Poppins', sans-serif;
  color:white;
  letter-spacing: 1px;
  font-size: 48px;
}
.Home_content_section p{
  color:white;
  padding:0;
  margin:0;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  line-height: 30px;
}
.Home_Images_section{
  padding-top:225px;
}
.Home_Images_section img{
  width: 100%;
  height:100%;
}
.Button_section_home{
  padding-top:27px;
  display:flex;
  gap:20px;
}
.Button_section_home button{
  padding-left:30px;
  padding-right:30px;
  padding-top:6px;
  padding-bottom:6px;
  border:none;
}
.Home_content_section .Book_Appoinment{
  background:#AF864E;
  color:white;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  padding-top:10px;
  padding-bottom:10px;
  border-radius: 8px;
}
.Home_content_section .Book_Know{
  border:1px solid #AF864E;
  background:transparent;
  color:white;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  border-radius: 8px;
}
.Collage_section_main{
  /* background:rgb(223, 13, 13); */
  padding-left:160px;
  padding-right:160px;
  position: relative;
  top:80px;

}
.Collages_section .Cloages_Parent_section{
  padding-left: 0;
  z-index: 9999;
  padding-right:20px;

}
.Collages_section .Cloages_Parent_section .Child_section{
  background:#141414;
   border-radius: 8px;
   display:flex;
   justify-content: center;
   flex-direction: column;
   padding-bottom:40px;
   box-shadow: 6px 6px 20px -1px #282727;
   padding-top:20px;

}
.Collages_section .Cloages_Parent_section .Child_section img{
  width:100px;
  height:100px;
  display: flex;;
}
.Collages_section .Cloages_Parent_section .Child_section h5{
  color:white;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  padding-top:18px;
  text-align:center;
}

.About_section-main{
  background:#141414;
  padding-top:150px;
  border-radius: 16px;

  
} 
.About_section_main_main .About_section-main .row{
  border-top:1px solid #D4B26B;
  padding-top:23px;
}
.About_section_main_main .About_section-main .Parent_section img{
  width:100%;
  height:100%;
}
.About_section_main_main .About_section-main .Parent_section h2{
  padding:0;
  margin:0;
  padding-top:50px;
  padding-bottom:16px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color:white;
}
.About_section_main_main .About_section-main .Parent_section h5{
  padding:0;
  margin:0;
  text-align: center;
  padding-bottom:30px;
  color:white;
  font-size: 20px;
}
.About_section_main_main .About_section-main .Parent_section  p{
  padding:0;
  margin:0;
  font-family: 'Poppins', sans-serif;
  color:white;
  text-align: justify;
  letter-spacing: 1px;
  font-size:16px;
  text-align: center;
  padding-bottom: 25px;
}
.No1_Career_Cansulting_section{
  background:black;
}
.No1_Career_Cansulting_section_Main_section{
  position: relative;
}
.No1_Career_Cansulting_section_Main_section_child_section{
  padding:0;
  margin:0;
  padding-right:100px;

}
.No1_Career_Cansulting_section_Main_section_child_section div{
  padding-top:17px;
  text-align:center;
  width:100%;
}
.No1_Career_Cansulting_section_Main_section_child_section div h1{
  padding-left:60px;
  padding-bottom:0;
  color:white;
  color:#AF864E;
  font-weight:600;
  font-family: 'Poppins', sans-serif;
  padding-top:19px;
}
.No1_Career_Cansulting_section_Main_section_child_section div p{
  padding-left:60px;
  padding-top:0;
  color:white;
  font-family: 'Poppins', sans-serif;
  font-size:14px;
  letter-spacing: 1px;
  font-weight:300;
}
.No1_Career_Cansulting_section_Main_section_child_section img{
  margin-bottom:50px;
  position: absolute;
  top:-33px;
}
.Gallery_section_main{
  background:black;
  padding-left:60px;
  padding-right:60px;
}
.Gallery_section_main .Gellery_Sectound_section{
  background:#2D271F;
  padding-left:100px;
  padding-right:100px;
  border-radius: 16px;
  padding-bottom:100px;
}
.Gallery_section_container h1{
  padding:0;
  margin:0;
  text-align: center;
  padding-top:30px;
  padding-bottom: 30px;
  font-family: 'Poppins', sans-serif;
  color:#AF864E;
  font-weight:500;
}
.Gallery_section_2{
  border-radius: 8px;
  padding:0;
 height:400px;
  margin:0;
}
.Gallery_section_2 .Gallery_images_section{
  height:100%;
  width:100%;
  border-radius: 8px;
}
.Gallery_section_2 .Gallery_images_section img{
  width:100%;
  height:100%;
}
.Gallery_section_4{
  height:300px;
  padding-left:30px;
  padding-right:30px;
  height:400px;
  padding-right:0;
}
.Gallery_section_4 .Gallery_section_images{
  height:100%;
  width:100%;
  border-radius: 8px;

}
.Gallery_section_4 .Gallery_section_images img{
  width:100%;
  height:100%;
}
.Gallery_secound_section{
  padding-top:32px;
  padding-left:0;
  padding-right:0;
  overflow: hidden;
  padding-right:30px;
}
.Gallery_images_section_secound .Gellory_secound_all_imasges{
  border-radius:8px;
  margin-bottom:32px;
  padding-left:0;
}
.Gallery_images_section_secound .Gellory_secound_all_imasges img{
  width:100%;
}
.Gallery_secound_images_section{
  padding-right:0;
  padding-top:32px;
  padding-bottom:32px;
}
.Gallery_secound_images_section .Secound_images_section_right img{
  width:100%;
  height:100%;
}
.Gallery_secound_images_section .Secound_images_section_right{
  height:100%;
  width:100%;
  border-radius: 8px;
}
.Team_secing_main{
  background:black;
  height:100px;
  position: relative;

}
.Team_secing_main .TEam_section_secound{
  padding-left:160px;
  padding-right:160px;
  position: absolute;
  top:-60px;
  width:100%;
}
.Team_secing_main .TEam_section_secound .Team_section_all{
  background:#141414;
  padding-bottom:40px;
  padding-left:40px;
  padding-right:40px;
  border-radius: 8px;

}
.Events_section{
  border-bottom: 1px solid #D4B26B;
}
.Events_section h1{
  padding:0;
  margin:0;
  text-align: center;
  padding-bottom:25px;
  padding-top:40px;
  color:white;
  font-family: 'Poppins', sans-serif;
}
.Images_Event_section{
  padding-top:30px;
  gap:20px;padding:10px;
}
.Images_Event_section .Images img{
  width:100%;
  height:100%;
}
.TExt_section_main h4{
  padding:0;
  margin:0;
  padding-top:100px;
  color:white;
  font-family: 'Poppins', sans-serif;
  padding-bottom:4px
}
.TExt_section_main h6{
  color:white;
  font-family: 'Poppins', sans-serif;
  padding-bottom:4px
}
.TExt_section_main P{
  color:white;
  font-family: 'Poppins', sans-serif;
  FONT-SIZE:16px;
  font-weight:200;
}
.TExt_section_main button{
  border:none;
  width:100%;
  padding-top:10px;
  padding-bottom:10px;
  border-radius: 8px;
  color:white;
  border:1px solid white;
  background:transparent;
}
.Testimonial_section_main{
  background:black;
  padding-left:60px;
  padding-right:60px;
}
.Testmonial_section_secound{
  background:#191919;
  border-radius: 8px;
}
.Button_Event{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap:10px;
}
.Button_Event .button_sec{
  width:100%;
  height:100%;
  background:#AF864E;
  border:none;
}

.Testimonial_section_main_main_container{
  text-align: center;
  padding-left:0;
  padding-right:0;
  display:flex;
  flex-direction: column;
  justify-content: center;
}
.Testimonial_section_main_main_container h1{
  padding:500px;
  font-family: 'Poppins', sans-serif;
  padding-bottom:100px;
  color:white;
  width:100%;
  padding-left:0;
  padding-right: 0;
}
.Extremely_Simple_section_main{
  padding-bottom:20px;
  padding-left:30px;
  padding-right:30px;
}
.Text_Testimonial_main{
  color:white;
  z-index: 9999;
  text-align: center;
}
.Text_Testimonial_main h4{
  padding:0;
  margin:0;
  padding-top:11px;
  padding-bottom:6px;
  font-family: 'Poppins', sans-serif;
}
.Text_Testimonial_main p{
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}
.Qustion_section_main{
  background:black;
  padding-left:60px;
  padding-right:60px;
  padding-top:64px;
}
.Qustion_section_main .Qustion_secound_section{
  background:#1A1713;
  border-radius: 8px;
  padding-bottom:50px;
  padding-right:100px;
  padding-right:100px;
  position: relative;
}
.Qustion_section_container h1{
  padding:0;
  margin:0;
  text-align: center;
  padding-top:40px;
  font-family: 'Poppins', sans-serif;
  font-weight:600;
  color:white;
  padding-bottom:60px;
}
.Qustion_section_container .Qustion_images_section img{
  width:100%;
  height:100%;
}
.Qustion_section_text{
  padding:0;
  margin:0;
}
.Main_qustion_section{
  background:#FFF2DF;
  padding:10px;
  font-family: 'Poppins', sans-serif;
  border-radius: 8px;
  margin-bottom:10px;

}
.Main_qustion_section .Qustion{
  display:flex;
  justify-content: space-between;
}
.Main_qustion_section .Qustion i{
  font-size:25px;
  font-weight:600;
}
.accordion-title{
  padding-top:10px;
  padding-bottom:10px;
}
.accordion-item{
  border:1px solid white;
  border-radius: 8px;
}

.Blog_section_main{

}
.Blog_section{
  height: 750px;
  width:100%;
  position:relative;
  
}
.Blog_section .row{
  background: #141414;
  position: absolute;
  top:-50px;
  width:100%;
  border-radius: 8px;
  padding-bottom:30px;

}
.Blog_section .row .Blog_text_section h1{
  text-align: center;
  padding-top:50px;
  font-family: 'Poppins', sans-serif;
  color:white;
}

.Blog_section_maion_div{
  padding-left:32px;
  padding-right:32px;
}
.Blog_section_maion_div .Blog_section_text{
  padding:15px;
  border-radius: 8px;
  width:100%;
  background:#191919;
}
.Blog_section_maion_div .Blog_section_text h6{
  color:white;
  padding-top:15px;
  font-family: 'Poppins', sans-serif;
}
.Blog_section_maion_div .Blog_section_text p{
  color:white;
  font-family: 'Poppins', sans-serif;
  text-align: justify;
}
.Blog_section_maion_div .Blog_section_text button{
  background:transparent;
  padding-right:20px;
  border: none;
  color:#c29d4e;
}
.Text_blog{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.Text_blog img{
  width:100%;
  height:100%;
}

.BLog_section_main .Blog_section_all{
  position: relative;
}.Blog_section_text_main{
  padding-left:32px;
  padding-right:32px;
  padding-bottom: 15px;
}
.BLog_section_main .Blog_section_all .Blog_images_section_all{
  border-radius: 8px;
  padding:15px;
  background:#191919;
}
.BLog_section_main .Blog_section_all .Blog_images_section_all h6{
  padding-top:10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color:white;
}
.BLog_section_main .Blog_section_all .Blog_images_section_all p{
  padding:0;
  margin:0;
  font-family: 'Poppins', sans-serif;
  text-align: justify;
  font-size:16px;
  padding-bottom:10px;
  color:white;
}
.BLog_section_main .Blog_section_all .Blog_images_section_all button{
  background:transparent;
  border:none;
  color:#D4B26B;
}
.Blog_images_section_text{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

}
.Blog_images_section_text img{
  width:100%;
  height:100%;
}
 @media only screen and (max-width: 832px) {
  .card .primary-heading {
      font-size: 3.5em;
  }

  .footer-heading {
      font-size: 1.5em;
  }
}

@media only screen and (max-width: 626px) {

  .header-mobile {
      display: block;
  }

  .main-header {
      display: none;
  }

  .card .primary-heading {
      font-size: 3em;
  }
}

@media only screen and (max-width: 480px) {

  .card .primary-heading {
      font-size: 2em;
  }
}

@media only screen and (max-width: 365px) {

  .card .primary-heading {
      font-size: 2em;
  }
}
@media (max-width: 768px){
    .navbar{
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines{
        display: block;
    }

    .navbar-container{
        display: block;
        position: relative;
        height: 64px;
    }

    .navbar-container input[type="checkbox"]{
        position: absolute;
        display: block;
        height: 32px;
        width: 30px;
        top: 20px;
        left: 20px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }

    .navbar-container .hamburger-lines{
        display: block;
        height: 28px;
        width: 35px;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line{
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background:white;
    }
    
    .navbar-container .hamburger-lines .line1{
        transform-origin: 0% 0%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2{
        transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3{
        transform-origin: 0% 100%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar .menu-items{
        padding-top: 50px; 
        background:black;
        width: 500px;
        transform: translate(-150%);
        display: flex;
        flex-direction: column;
        margin-left: -40px;
        padding-left: 40px;
        transition: transform 0.5s ease-in-out;
        overflow: scroll;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        padding-bottom:50px;
        height:100vh;
    }

    .navbar .menu-items li{
        text-align: left;
        font-size: 1.1rem;
        font-weight: 500;
    }

    .logo{
        position: absolute;
        right: 15px;
        font-size: 2.5rem;
    }

    .navbar-container input[type="checkbox"]:checked ~ .menu-items{
        transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1{
        transform: rotate(45deg);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2{
        transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3{
        transform: rotate(-45deg);
    }

}

@media (max-width: 500px){
    .navbar-container input[type="checkbox"]:checked ~ .logo{
        display: none;
    }
} 
/* footer section */
 .Footer_section_main{
    background:black;
}
ul {
    margin: 0px;
    padding: 0px;
}
.footer-section {
  background: #151414;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #c29d4e;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: white;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
    max-width: 200px;
}
.footer-text h1{
  color:white;
  font-family: 'Poppins', sans-serif;
  padding-bottom:30px;
}
.footer-social-icon .Search_input{
    width:70%;
    border-radius: 30px;
    padding-left:20px;
    background:transparent;
    border:1px solid rgb(65, 64, 64);
}
.footer-social-icon  .Submit_btn{
  border-radius: 30px;
  padding:15px;
  margin-left:10px;
  background:#AF864E;
  padding-left:15px;
  padding-right:15px;
  border:none;
  color:white;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg{
  background: #3B5998;
}
.twitter-bg{
  background: #55ACEE;
}
.google-bg{
  background: #D5B36B;
}
.footer-widget-heading h3 {
  color: #c29d4e;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
  text-align: center;
}
.footer-widget ul{
  padding-top:100px;
}
.footer-widget ul li {
  margin-bottom: 12px;
  text-align: left;
  list-style: none;
  padding-left:50px;
  padding-bottom: 10px;
  text-decoration: none;
}
.footer-widget ul li a:hover{
  color: #D5B36B;
}
.footer-widget ul li a {
  color:white;
  text-transform: capitalize;
  text-decoration: none;
}
.footer-widget ul img{
  padding-left:50px;
}
.copyright-area{
  background:black;
  padding: 25px 0;
  display:flex;
  align-items: center;

}
.copyright-area .container{
  border-top:1px solid #D5B36B;
  padding-top:40px;
}
.copyright-text p {
  color:white;
}

.footer-menu ul {
  display:flex;
  gap:30px;
  justify-content: end;
  list-style: none;
  padding-bottom:20px;
}

.footer-menu li a {
  font-size: 14px;
  color: white;
  text-decoration: none;
} 
/* latest blog section  */

.Blog_other h1{
  padding:0;
  margin:0;
  color:white;
  padding-bottom:20px;
  padding-left:15px;
}
 .text_blog_top_section{
  text-align: center;
  padding-bottom:60px;

}
.latest_blog_section{
  background:black;
}
.text_blog_top_section h1{
  padding:0;
  margin:0;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  padding-bottom: 10px;
}
.text_blog_top_section p{
  padding:0;
  margin:0;
  font-family: 'Poppins', sans-serif;
}
.latest_blog_section .blog_section{
  background-repeat: no-repeat;
  background-size: cover;
  padding-top:100px;
  padding-bottom:100px;
}
.blog_section_child{
 
}
.blog_child_section_shadow{
  box-shadow: rgba(102, 102, 102, 0.35) 0px 2px 10px;
  padding:10px;
  border-radius: 6px;
}
.blog_section_child .blog_section_Images{
  border-radius: 7px;
}
.blog_section_child .blog_section_Images img{
  width:100%;
  height: 100%;
  border-radius: 7px;
}
.Blog_text_section{
  padding:15px;
}
.Blog_text_section button{
  padding-left:30px;
  padding-right:30px;
  padding-top:6px;
  padding-bottom:6px;
  border-radius: 7px;
  border:none;
  color:white;
  font-family: 'Poppins', sans-serif;
  background: #D5B36B;
}
.Blog_text_section .User_section{
  display:flex;
  justify-content: space-between;
  padding-top:20px;
}
.Blog_text_section .User_section .left_user{
  display:flex;
  gap:7px;
  font-size:18px;
  font-family: 'Poppins', sans-serif;
}
.Blog_text_section .User_section .right_user{
  display:flex;
  gap:5px;
  font-family: 'Poppins', sans-serif;
}
.Blog_text_section h5{
  padding:0;
  margin:0;
  font-family: 'Poppins', sans-serif;
  padding-top:10px;
  font-weight:600;
  padding-bottom:10px;
}
.Blog_text_section h6{
  padding:0;
  margin:0;
  font-family: 'Poppins', sans-serif;
  padding-top:20px;
} 
/* form */

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, input, select, textarea {
  color: #5A5A5A;
  font: inherit;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

input {
  line-height: normal;
  font-family: 'Poppins', sans-serif;
}

textarea {
  overflow: auto;
  font-family: 'Poppins', sans-serif;
}


form {
  margin: 50px 0;
}





.icon_wrapper {
  margin: 50px auto 0;
  width: 100%;
}

.icon {
  display: block;
  fill: #e4550e;
  height: 50px;
  margin: 0 auto;
  width: 50px;
}

.email {
  float: right;
  width: 45%;
  font-family: 'Poppins', sans-serif;
}

input[type='text'], [type='email'], select, textarea {
  background:white;
  border: none;
  border-bottom: solid 2px #DABD81;
  padding-left:16px;
  color: #111010;
  font-size: 1.000em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  width: 100%;
  line-height: 56px;
  -webkit-box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-radius:8px;
}

input[type='text']:focus, [type='email']:focus, textarea:focus {
  outline: none;
  padding-left:16px;
  font-family: 'Poppins', sans-serif;
}

.message {
  float: none;
}

.name {
  float: left;
  width: 45%;
}

select {
  /* background: url('https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-down-32.png') no-repeat right; */
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

.subject {
  width: 100%;
}

.telephone {
  width: 100%;
}

textarea {
  line-height: 150%;
  height: 150px;
  resize: none;
  width: 100%;
}

::-webkit-input-placeholder {
  color: #474544;
}

:-moz-placeholder { 
  color: #474544;
  opacity: 1;
}

::-moz-placeholder {
  color: #474544;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #474544;
}

#form_button {
  background: none;
  border: solid 2px #ecb663;
  color: #474544;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875em;
  font-weight: bold;
  outline: none;
  padding-left:50px;
  padding-right:50px;
  padding-top:8px;
  padding-bottom:8px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 6px;
  font-family: 'Poppins', sans-serif;
  color:#c29d4e;
}

#form_button:hover {
  background: #ffdd7f;
  color: #F2F3EB;
}

@media screen and (max-width: 768px) {
  #container {
    margin: 20px auto;
    width: 95%;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 26px;
  }
  
  #form_button {
    padding: 15px 25px;
  }
}

@media screen and (max-width: 420px) {
  h1 {
    font-size: 18px;
  }
  
  .icon {
    height: 35px;
    width: 35px;
  }
  
  .underline {
    width: 53px;
  }
  
  input[type='text'], [type='email'], select, textarea {
    font-size: 0.875em;
  }
}

/* blog new pages */
 .Blog_section_section{
  background:#000000;
  padding-top:100px;
  padding-bottom:100px;
  padding-top:200px;

}

.Text_blog_section h1{
  padding:0;
  margin:0;
  color:white;
  font-family: 'Poppins', sans-serif;
  font-weight:700;
  padding-top:40px;
  padding-bottom:40px;
}
.Text_blog_section h6{
  color:#D4B26B;
  font-size:24px;
}
.Blog_section_text_section{
  background:black;
  padding-top:20px;
  padding-bottom:60px;
}
.Blog_section_text_section .text_blog_section p{
  padding:0;
  margin:0;
  font-family: 'Poppins', sans-serif;
  font-size:16px;
  text-align: justify;
  line-height: 30px;
  color:white;
}
.Blog_section_text_section .col-4{
  padding:0;
  margin:0;
}
.Blog_section_text_section .col-4 img{
  width:100%;
  height:100%;
}
.Blog_section_text_section .col-12{
  padding:0;
  margin:0;

}
.Blog_section_text_section .col-12 p{
  padding-top:10px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  text-align: justify;
  padding:10px;
  color:white;
}
.images_section{
  padding:0;
  margin:0;
}
.images_section img{
  width:100%;
  height:100%;
  object-fit: cover;
}
.Bolg_section_deatials{
  background:#000000;
  padding-top:150px;
}
.Bolg_section_deatials .col-5{
  padding:0;
  margin:0;
}
.Bolg_section_deatials .col-5 img{
  width:100%;
  height:100%;
}
.Bolg_section_deatials .row{
  display:flex;
  align-items: center;
}
.Bolg_section_deatials .col-7 h6{
  color:white;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  padding-top:250px;
}
.Blog_section_Deatials_items{
  background:red;
}
.Blog_section_Deatials_items .Blog_text_section_main{
  background:rgb(71, 70, 70);
  height:400px;
}
.text_blog_section_Deatials{
  background:red;
}
.View_more{
  width:100%;
  height:50px;
  display:flex;
  justify-content: center;
  padding-top:20px;
  padding-bottom:20px;
}
.View_more button{
  background:#c29d4e;
  color:white;
height:40px;
width:200px;
border-radius: 7px;
border: none;
}
.Blog_section_all .col-lg-3{
  display:flex;
  column-gap:40px;
  margin-bottom: 24px;
}
.latest_blog_sections{
  padding-top:0;
  padding-bottom:50px;
  padding-left:160px;
  padding-right: 160px;
  background:black;
}
.Blog_search_filuter{
  display:flex;
  align-items: center;
  background:black;
}
.Search_section{
  padding-top:20px;
}
.Search_section input{
  border:1px solid rgb(66, 65, 65);
  background:transparent;
  color:white;
}
.Search_section{
  background:black;
}
.Choose_pages_section{
  padding:20px;
  display: flex;
}
.Choose_pages_section i{
  color:white;
  padding-top:16px;
}




.custom-select-container {
  position: relative;
  width: 100%;
  height:50px;
}

.custom-select {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.custom-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}







.Choose_pages_section select{
  border:1px solid rgb(66, 65, 65);
  background:transparent;
  color:white;
} 
.Term_section{
  padding-left:160px;
  padding-right:160px;
  padding-top:160px;
  background:black;
}
.Term_section .container-fluid{
  background:black;
  padding:0;
  margin:0;
}
.Term_section .container-fluid .card{
  background:black;
  color:white;
}
@media screen and (min-width:1024px) and (max-width:1600px) { 
  .footer-widget ul img{
    padding-left:30px;
  }
  @media screen and (max-width:1360px) {
    .footer-widget ul img{
      padding-left:20px;
      width:60px;
    }
    .Footer_section_main{
      background:black;
      padding-left:60px;
      padding-right: 60px;
  }
  .Blog_section_main{
    background:black;
    padding-left: 0px;
    padding-right:0px;
    position:relative;
  }
  .Blog_section{
    height: 750px;
    width:100%;
    position:relative;
    
  }
  .Blog_section .row{
    background: #141414;
    position: absolute;
    top:-50px;
    width:100%;
    border-radius: 8px;
    padding-bottom:30px;
  
  }
  }
  @media screen and (max-width:1136px) {
    .footer-widget ul img{
      padding-left:20px;
      width:50px;
    }
    .Footer_section_main{
      background:black;
      padding-left:60px;
      padding-right: 60px;
  }
  .Blog_section{
    width:100%;    
  }
  .Blog_section .row{
    background: #141414;
    position: absolute;
    top:-50px;
    width:100%;
    border-radius: 8px;
    padding-bottom:30px;
  
  }
  .Qustion_section_main{
    background:black;
    padding-left:0px;
    padding-right:0px;
    padding-top:64px;
  }
  .Qustion_section_main .Qustion_secound_section{
    background:#1A1713;
    border-radius: 8px;
    padding-left:50px;
    padding-right:50px;
    padding-bottom:50px;
    position: relative;
  }
  }
}
@media screen and (min-width:1000px) and (max-width:1300px) {
  .Term_section{
    padding-top:160px;
    background:black;
  }
  .navbar{
    width: 100%;
    background:transparent;
    padding-left:0px;
    padding-right:0px;
    position: fixed;
    top:0;
    left:0;z-index: 10000;
    transition: all 0.3s ease;
}
.affix{
  background: black;
}
.affix .navbar-container{
  border:none;
}server -s build

  .Home_banner_section{
    background:url('../../../public/Asstes/Images/BG_Banner.png');
    background-size:cover;
    background-repeat: no-repeat;
    padding-left:20px;
    padding-right:20px;
    
  }
  .Home_Images_section img{
    width:100%;
    height:100%;
  }
/* blog section  */
.Blog_section_main{
  padding-left: 0px;
  padding-right:0px;
}
.Blog_section{
  width:100%;
  padding:0;
  margin:0;
  display:flex;
  justify-content: center;

  
}
.Blog_section .row{
  width:100%;
  border-radius: 8px;
  padding-bottom:30px;

}
.Blog_section_maion_div{
  padding:0;
  margin:0;
}

/* blog section pages */
.Bolg_section_deatials{
  background:#000000;
  padding-top:150px;
  padding-left:0px;
  padding-right:0px;
}
.Blog_search_filuter{
  display:flex;
  align-items: center;
  padding-left:0px;
  padding-right: 0px;
  background:black;
}
.Choose_pages_section{
  padding-top:20px;
  padding-left:0;
  padding-right:10px;
}
.Blog_section_main{
  background:black;
  padding-left: 0px;
  padding-right:0px;
  position:relative;
}
.Blog_section_text_main{
  padding-left:0px;
  padding-right:0px;
  padding-bottom: 15px;

}
/* details pages blog */
.Blog_section_section{
  background:#000000;
  padding-top:100px;
  padding-bottom:100px;
  padding-top:200px;
  padding-left:0px;
  padding-right:0px;

}
.Blog_section_text_section{
  background:black;
  padding-top:20px;
  padding-bottom:60px;
  padding-left:0px;
  padding-right:0px;
}

.Book_appoinents_sectyion_main {
  background-size:cover;
  background-repeat: no-repeat;
  padding-top:100px;
  height:720px;
  padding:10px;


}
.Book_appoinent_section{
  padding-bottom:0;
  margin-bottom: 0;
  position: relative;
  
}
.Book_appoinent_section div img{
  width:100%;
  height:100%;
  object-fit:cover ;
  padding-top:150px;
  position: relative;
}
.form_section_main{
  position: absolute;
  top:0;
  left:0;
  z-index: 9999;
  width:100%;
  padding-left:0px;
}
.form_section_main h1{
  color:white;
  font-size:32px;
  font-weight:500;
  font-family: 'Poppins', sans-serif;
  padding-top:150px;
}
.Number{
  background:white;
    border: none;
    border-bottom: solid 2px #DABD81;
    color:rgb(0, 0, 0);
    font-size: 1.000em;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0em 0 1.875em 0;
    width: 100%;
    line-height: 56px;
    -webkit-box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    padding-left:16px;
    outline: none;
    font-weight: 500;
    border-radius:8px;
}
.Date_section{
  background:white;
  border: none;
  border-bottom: solid 2px #DABD81;
  color: #111010;
  font-size: 1.000em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  outline: none;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 56px;
  border-radius:8px;
  font-weight: 500;
}
.Meassges{
  background:white;
  border: none;
  border-bottom: solid 2px #DABD81;
  color: B1B1B1;
  font-size: 1.000em;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  padding: 0 0 0.875em 0;
  height:112px;
  width: 100%;
  -webkit-box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-radius:8px;
  outline: none;
}
.Form_section button{
  width:100%;
  line-height:40px;
  background:#AF864E;
  border:none;
  border-radius: 5px;
  color:white;
  font-size: 16px;
  font-weight:600;
}
.Form_section{
  display:flex;
  flex-direction: column;
  column-gap: 24px;
}
.Input_section_date{
  display:flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  position: relative;
}
.Input_section_date .Date_img{
  width:48px;
  height:48px;
  padding-top:0;
  margin-bottom:25px;
  position: absolute;
  right:0px;
  object-fit: cover;
} 

/* team */
.Team_secing_main .TEam_section_secound{
  padding-left:0px;
  padding-right:0px;
  position: absolute;
  top:-60px;
  width:100%;
}
.Team_secing_main .TEam_section_secound .Team_section_all{
  background:#141414;
  padding-bottom:40px;
  padding-left:10px;
  padding-right:10px;
  border-radius: 8px;

}
/* testimonial */
.Testimonial_section_main{
  background:black;
  padding-left:0px;
  padding-right:0px;
}
.Testmonial_section_secound{
  background:#191919;
  border-radius: 8px;
}
.Testimonial_section_main_main_container .row{
  padding-left:0px;
  padding-right:0px;
}
.Testimonial_section_main_main_container h1{
  padding:500px;
  font-family: 'Poppins', sans-serif;
  padding-bottom:100px;
  color:white;
  padding-left:0;
  padding-right:0;
  text-align: center;
}
}
@media screen and (min-width:770px) and (max-width:1000px){
  .Term_section{
    padding-left:20px;
    padding-right:20px;
    padding-top:160px;
    background:black;
  }
  .navbar{
    width: 100%;
    background:transparent;
    padding-left:0px;
    padding-right:0px;
    position: fixed;
    top:0;
    left:0;z-index: 10000;
    transition: all 0.3s ease;
}
.affix{
  background: black;
}
.affix .navbar-container{
  border:none;
}
  .Home_banner_section{
    background:url('../../../public/Asstes/Images/BG_Banner.png');
    background-size:cover;
    background-repeat: no-repeat;
    padding-left:20px;
    padding-right:20px;
    
  }
  .Home_Images_section{
    padding-top:60px;
  }
  .Home_Images_section img{
    width: 100%;
    height:100%;
  }
  .Collage_section_main{
    padding-left:20px;
    padding-right:20px;
    position: relative;
    top:80px;
  
  }
  .Collages_section .Cloages_Parent_section{
    padding-top:20px;
    padding-bottom:20px;
    padding-left: 0;
    z-index: 9999;
  
  }
  .Collages_section .Cloages_Parent_section{
    padding-left: 0;
    z-index: 9999;
    padding-right:0px;
  
  }
  .About_section_main_main{
    width:100%;
    padding-left:0px;
    padding-right:0px;
    background:#141414;
  }
  .About_section_main_main .About_section-main .row{
    border-top:1px solid #D4B26B;
    padding-top:23px;
    margin-left:0px;
    margin-right:0px;
  }
  .Gallery_section_main{
    background:black;
    padding-left:0px;
    padding-right:0px;
  }
  .Gallery_section_2 img{
    width:100%;
    height:100%;
  }
  .Gallery_section_main .Gellery_Sectound_section{
    background:#2D271F;
    padding-left:20px;
    padding-right:20px;
    border-radius: 16px;
    padding-bottom:100px;
  }
  .Gallery_section_4{
    padding:0;
    margin:0;
  }
  .Gallery_secound_section{
    padding-top:32px;
    padding-left:0;
    padding-right:0;
    overflow: hidden;
    border-radius: 8px;
  }
  .Gallery_secound_images_section{
    padding-right:0;
    padding-left:0;
    padding:10px;
  }
  .Qustion_section_main{
    background:black;
    padding-left:0px;
    padding-right:0px;
    padding-top:64px;
  }
  .Qustion_section_main .Qustion_secound_section{
    background:#1A1713;
    border-radius: 8px;
    padding-left:20px;
    padding-right:20px;
    padding-bottom:50px;
    position: relative;
  }
  .Blog_section_main{
    background:black;
    padding-left: 0px;
    padding-right:0px;
    height:2300px;
  }
  .Blog_section{
    width:100%;
  
    
  }
  .Blog_section .row{
    background: #141414;
    width:100%;
    border-radius: 8px;
    padding-bottom:30px;
  
  }
  .Footer_section_main{
    background:black;
    padding-left:0px;
    padding-right: 0px;
    padding-top:200px;
  }
  /* blog section  */
.Blog_section_main{
  padding-left: 0px;
  padding-right:0px;
}
.Blog_section{
  width:100%;
  padding:0;
  margin:0;
  display:flex;
  justify-content: center;

  
}
.Blog_section .row{
  width:100%;
  border-radius: 8px;
  padding-bottom:30px;

}
.Blog_section_maion_div{
  padding:0;
  margin:0;
}

/* blog section pages */
.Bolg_section_deatials{
  background:#000000;
  padding-top:150px;
  padding-left:0px;
  padding-right:0px;
}
.Blog_search_filuter{
  display:flex;
  align-items: center;
  padding-left:0px;
  padding-right: 0px;
  background:black;
}
.Choose_pages_section{
  padding-top:20px;
  padding-left:0;
  padding-right:10px;
}
.Blog_section_main{
  background:black;
  padding-left: 0px;
  padding-right:0px;
  position:relative;
}
.Blog_section_text_main{
  padding-left:0px;
  padding-right:0px;
  padding-bottom: 15px;

}
/* details pages blog */
.Blog_section_section{
  background:#000000;
  padding-top:100px;
  padding-bottom:100px;
  padding-top:200px;
  padding-left:0px;
  padding-right:0px;

}
.Blog_section_text_section{
  background:black;
  padding-top:20px;
  padding-bottom:60px;
  padding-left:0px;
  padding-right:0px;
}

.Book_appoinents_sectyion_main {
  background-size:cover;
  background-repeat: no-repeat;
  padding-top:100px;
  height:800px;
  padding:10px;


}
.Book_appoinent_section{
  padding-bottom:0;
  margin-bottom: 0;
  position: relative;
  
}
.Book_appoinent_section div img{
  width:100%;
  height:100%;
  object-fit:cover ;
  padding-top:150px;
  position: relative;
}
.form_section_main{
  position: absolute;
  top:0;
  left:0;
  z-index: 9999;
  width:100%;
  padding-left:0px;
}
.form_section_main h1{
  color:white;
  font-size:32px;
  font-weight:500;
  font-family: 'Poppins', sans-serif;
  padding-top:250px;
}
.Number{
  background:white;
    border: none;
    border-bottom: solid 2px #DABD81;
    color:rgb(0, 0, 0);
    font-size: 1.000em;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0em 0 1.875em 0;
    width: 100%;
    line-height: 56px;
    -webkit-box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    padding-left:16px;
    outline: none;
    font-weight: 500;
    border-radius:8px;
}
.Date_section{
  background:white;
  border: none;
  border-bottom: solid 2px #DABD81;
  color: #111010;
  font-size: 1.000em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  outline: none;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 56px;
  border-radius:8px;
  font-weight: 500;
}
.Meassges{
  background:white;
  border: none;
  border-bottom: solid 2px #DABD81;
  color: B1B1B1;
  font-size: 1.000em;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  padding: 0 0 0.875em 0;
  height:112px;
  width: 100%;
  -webkit-box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-radius:8px;
  outline: none;
}
.Form_section button{
  width:100%;
  line-height:40px;
  background:#AF864E;
  border:none;
  border-radius: 5px;
  color:white;
  font-size: 16px;
  font-weight:600;
}
.Form_section{
  display:flex;
  flex-direction: column;
  column-gap: 24px;
}
.Input_section_date{
  display:flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  position: relative;
}
.Input_section_date .Date_img{
  width:48px;
  height:48px;
  padding-top:0;
  margin-bottom:25px;
  position: absolute;
  right:0px;
  object-fit: cover;
} 

/* team */
.Team_secing_main .TEam_section_secound{
  padding-left:0px;
  padding-right:0px;
  position: absolute;
  top:-60px;
  width:100%;
}
.Team_secing_main .TEam_section_secound .Team_section_all{
  background:#141414;
  padding-bottom:40px;
  padding-left:10px;
  padding-right:10px;
  border-radius: 8px;

}
/* testimonial */
.Testimonial_section_main{
  background:black;
  padding-left:0px;
  padding-right:0px;
}
.Testmonial_section_secound{
  background:#191919;
  border-radius: 8px;
}
.Testimonial_section_main_main_container .row{
  padding-left:0px;
  padding-right:0px;
}
.Testimonial_section_main_main_container h1{
  padding:1200px;
  font-family: 'Poppins', sans-serif;
  padding-bottom:100px;
  color:white;
  padding-left:0;
  padding-right:0;
  text-align: center;
}
}
@media screen and (min-width:551px) and (max-width:768px){
  .Term_section{
    padding-left:20px;
    padding-right:20px;
    padding-top:160px;
    background:black;
  }
  .navbar{
    width: 100%;
    background:transparent;
    padding-left:0px;
    padding-right:0px;
    position: fixed;
    top:0;
    left:0;z-index: 10000;
    transition: all 0.3s ease;
}
.affix{
  background: black;
}
.affix .navbar-container{
  border:none;
}
  .Home_banner_section{
    background:url('../../../public/Asstes/Images/BG_Banner.png');
    background-size:cover;
    background-repeat: no-repeat;
    padding-left:20px;
    padding-right:20px;
    
  }
  .Home_Images_section{
    padding-top:60px;
  }
  .Home_Images_section img{
    width: 100%;
    height:100%;
  }
  .Collage_section_main{
    padding-left:20px;
    padding-right:20px;
    position: relative;
    top:80px;
  
  }
  .Collages_section .Cloages_Parent_section{
    padding-top:20px;
    padding-bottom:20px;
    padding-left: 0;
    z-index: 9999;
  
  }
  .Collages_section .Cloages_Parent_section{
    padding-left: 0;
    z-index: 9999;
    padding-right:0px;
  
  }
  .About_section_main_main{
    width:100%;
    padding-left:0px;
    padding-right:0px;
    background:#141414;
  }
  .About_section_main_main .About_section-main .row{
    border-top:1px solid #D4B26B;
    padding-top:23px;
    margin-left:0px;
    margin-right:0px;
  }
  .Gallery_section_main{
    background:black;
    padding-left:0px;
    padding-right:0px;
  }
  .Gallery_section_2 img{
    width:100%;
    height:100%;
  }
  .Gallery_section_main .Gellery_Sectound_section{
    background:#2D271F;
    padding-left:20px;
    padding-right:20px;
    border-radius: 16px;
    padding-bottom:100px;
  }
  .Gallery_section_4{
    padding:0;
    margin:0;
  }
  .Gallery_secound_section{
    padding-top:32px;
    padding-left:0;
    padding-right:0;
    overflow: hidden;
    border-radius: 8px;
  }
  .Gallery_secound_images_section{
    padding-right:0;
    padding-left:0;
    padding:10px;
  }
  .Qustion_section_main{
    background:black;
    padding-left:0px;
    padding-right:0px;
    padding-top:64px;
  }
  .Qustion_section_main .Qustion_secound_section{
    background:#1A1713;
    border-radius: 8px;
    padding-left:20px;
    padding-right:20px;
    padding-bottom:50px;
    position: relative;
  }
  .Blog_section_main{
    background:black;
    padding-left: 0px;
    padding-right:0px;
    height:2300px;
  }
  .Blog_section{
    width:100%;
  
    
  }
  .Blog_section .row{
    background: #141414;
    width:100%;
    border-radius: 8px;
    padding-bottom:30px;
  
  }
  .Footer_section_main{
    background:black;
    padding-left:0px;
    padding-right: 0px;
    padding-top:140px;
  }
  /* blog section  */
.Blog_section_main{
  padding-left: 0px;
  padding-right:0px;
}
.Blog_section{
  width:100%;
  padding:0;
  margin:0;
  display:flex;
  justify-content: center;

  
}
.Blog_section .row{
  width:100%;
  border-radius: 8px;
  padding-bottom:30px;

}
.Blog_section_maion_div{
  padding:0;
  margin:0;
}

/* blog section pages */
.Bolg_section_deatials{
  background:#000000;
  padding-top:150px;
  padding-left:0px;
  padding-right:0px;
}
.Blog_search_filuter{
  display:flex;
  align-items: center;
  padding-left:0px;
  padding-right: 0px;
  background:black;
}
.Choose_pages_section{
  padding-top:20px;
  padding-left:0;
  padding-right:10px;
}
.Blog_section_main{
  background:black;
  padding-left: 0px;
  padding-right:0px;
  position:relative;
}
.Blog_section_text_main{
  padding-left:0px;
  padding-right:0px;
  padding-bottom: 15px;

}
/* details pages blog */
.Blog_section_section{
  background:#000000;
  padding-top:100px;
  padding-bottom:100px;
  padding-top:200px;
  padding-left:0px;
  padding-right:0px;

}
.Blog_section_text_section{
  background:black;
  padding-top:20px;
  padding-bottom:60px;
  padding-left:0px;
  padding-right:0px;
}

.Book_appoinents_sectyion_main {
  background-size:cover;
  background-repeat: no-repeat;
  padding-top:100px;
  height:720px;
  padding:10px;


}
.Book_appoinent_section{
  padding-bottom:0;
  margin-bottom: 0;
  position: relative;
  
}
.Book_appoinent_section div img{
  width:100%;
  height:100%;
  object-fit:cover ;
  padding-top:150px;
  position: relative;
}
.form_section_main{
  position: absolute;
  top:0;
  left:0;
  z-index: 9999;
  width:100%;
  padding-left:0px;
}
.form_section_main h1{
  color:white;
  font-size:32px;
  font-weight:500;
  font-family: 'Poppins', sans-serif;
  padding-top:150px;
}
.Number{
  background:white;
    border: none;
    border-bottom: solid 2px #DABD81;
    color:rgb(0, 0, 0);
    font-size: 1.000em;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0em 0 1.875em 0;
    width: 100%;
    line-height: 56px;
    -webkit-box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    padding-left:16px;
    outline: none;
    font-weight: 500;
    border-radius:8px;
}
.Date_section{
  background:white;
  border: none;
  border-bottom: solid 2px #DABD81;
  color: #111010;
  font-size: 1.000em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  outline: none;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 56px;
  border-radius:8px;
  font-weight: 500;
}
.Meassges{
  background:white;
  border: none;
  border-bottom: solid 2px #DABD81;
  color: B1B1B1;
  font-size: 1.000em;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  padding: 0 0 0.875em 0;
  height:112px;
  width: 100%;
  -webkit-box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-radius:8px;
  outline: none;
}
.Form_section button{
  width:100%;
  line-height:40px;
  background:#AF864E;
  border:none;
  border-radius: 5px;
  color:white;
  font-size: 16px;
  font-weight:600;
}
.Form_section{
  display:flex;
  flex-direction: column;
  column-gap: 24px;
}
.Input_section_date{
  display:flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  position: relative;
}
.Input_section_date .Date_img{
  width:48px;
  height:48px;
  padding-top:0;
  margin-bottom:25px;
  position: absolute;
  right:0px;
  object-fit: cover;
} 

/* team */
.Team_secing_main .TEam_section_secound{
  padding-left:0px;
  padding-right:0px;
  position: absolute;
  top:-60px;
  width:100%;
}
.Team_secing_main .TEam_section_secound .Team_section_all{
  background:#141414;
  padding-bottom:40px;
  padding-left:10px;
  padding-right:10px;
  border-radius: 8px;

}
/* testimonial */
.Testimonial_section_main{
  background:black;
  padding-left:0px;
  padding-right:0px;
}
.Testmonial_section_secound{
  background:#191919;
  border-radius: 8px;
}
.Testimonial_section_main_main_container .row{
  padding-left:0px;
  padding-right:0px;
}
.Testimonial_section_main_main_container h1{
  padding:1120px;
  font-family: 'Poppins', sans-serif;
  padding-bottom:100px;
  color:white;
  padding-left:0;
  padding-right:0;
  text-align: center;
}
}
@media screen and (max-width:550px){
.No1_Career_Cansulting_section{
  display:none;
}
}
@media screen and (min-width:550px){
  .No1_Career_Cansulting_section_mobile_section .Mobile_section_counsoling{
    padding-left:10px;
    padding-right:10px;
    display:flex;
    align-items: center;
    justify-content: center;
    display:none;
  }
  }
@media screen and (min-width:350px) and (max-width:550px){
  .Term_section{
    padding-left:20px;
    padding-right:20px;
    padding-top:160px;
    background:black;
  }
  .navbar{
    width: 100%;
    background:transparent;
    padding-left:10px;
    padding-right:10px;
    position: fixed;
    top:0;
    left:0;z-index: 10000;
    transition: all 0.3s ease;
}
.affix{
  background: black;
}
.affix .navbar-container{
  border:none;
}
/* home section */
.Home_banner_section{
  background:url('../../../public/Asstes/Images/BG_Banner.png');
  background-size:cover;
  background-repeat: no-repeat;
  padding-left:10px;
  padding-right:10px;
  
}
.Home_content_section h1{
  padding:0;
  margin:0;
  padding-top:236px;
  padding-bottom:27px;
  font-family: 'Poppins', sans-serif;
  color:white;
  letter-spacing: 1px;
  font-size: 28px;
  text-align: justify;
}

.Home_Images_section{
  padding-top:20px;
}
.Home_Images_section img{
  width: 100%;
  height:100%;
}
/* gallery section */
.Gallery_section_main{
  background:black;
  padding-left:0px;
  padding-right:0px;
}
.Gallery_section_main .Gellery_Sectound_section{
  background:#2D271F;
  padding-left:0px;
  padding-right:0px;
  border-radius: 16px;
  padding-bottom:100px;
}
.Gallery_section_main{
  background:black;
  padding-left:0px;
  padding-right:0px;

}
.Gallery_section_2 .Gallery_images_section img{
  height:100%;
  width:100%;
  border-radius: 8px;
  padding:10px;
}
.Gallery_section_4{
  height:300px;
  height:400px;
  padding-right:0;
  padding-left:0;
  padding:10px;
}
.Gallery_section_4 .Gallery_section_images{
  height:100%;
  width:100%;
  border-radius: 8px;

}
.Gallery_secound_images_section{
  padding-right:0;
  padding-left:0;
  padding:10px;
}
.Gallery_secound_section{
  padding-left:0;
  padding-right:0;
  overflow: hidden;
  border-radius: 8px;
  padding:10px;
}
.Collage_section_main{
  background:black;
  padding-left:10px;
  padding-right:10px;
  height:100%;

}
.Collages_section .Cloages_Parent_section{
  padding-top:20px;
  padding-bottom:20px;
  padding-left: 0;
  padding-right:0;
  z-index: 9999;

}
/* about us */
.About_section_main_main{
  width:100%;
  padding-left:0px;
  padding-right:0px;
  background:black;

}
.About_section-main{
  background:#141414;
  padding-top:30px;
  border-radius: 16px; 
  padding-left:0;
  padding-right:0;
} 
.About_section_main_main .About_section-main .row{
  border-top:1px solid black;
  padding-top:23px;
  margin-left:0px;
  margin-right:0px;
}
.About_section_main_main .About_section-main .Parent_section h2{
text-align: left;

}
.About_section_main_main .About_section-main .Parent_section h5{
  padding:0;
  margin:0;
  text-align: justify;
  padding-bottom:30px;
  color:white;
  font-size: 20px;
}
.About_section_main_main .About_section-main .Parent_section  p{
  padding:0;
  margin:0;
  font-family: 'Poppins', sans-serif;
  color:white;
  text-align:left;
  letter-spacing: 1px;
  font-size:16px;
  padding-bottom:40px;
}

/* team */
.Team_secing_main .TEam_section_secound{
  padding-left:0px;
  padding-right:0px;
  position: absolute;
  top:-60px;
  width:100%;
}
.Team_secing_main .TEam_section_secound .Team_section_all{
  background:#141414;
  padding-bottom:40px;
  padding-left:10px;
  padding-right:10px;
  border-radius: 8px;

}
/* testimonial */
.Testimonial_section_main{
  background:black;
  padding-left:0px;
  padding-right:0px;
}
.Testmonial_section_secound{
  background:#191919;
  border-radius: 8px;
}
.Testimonial_section_main_main_container .row{
  padding-left:0px;
  padding-right:0px;
}
.Testimonial_section_main_main_container h1{
  padding:1100px;
  font-family: 'Poppins', sans-serif;
  padding-bottom:100px;
  color:white;
  padding-left:10px;
  padding-right:10px;
  text-align: center;
}
.Qustion_section_main{
  background:black;
  padding-left:0px;
  padding-right:0px;
  padding-top:64px;
}
.Qustion_section_main .Qustion_secound_section{
  background:#1A1713;
  border-radius: 8px;
  padding-left:10px;
  padding-right:10px;
  padding-bottom:50px;
  position: relative;
}
.Footer_section_main{
  background:black;
  padding-left:0px;
  padding-right:0px;
  padding-top: 100px;;
}
.footer-social-icon .Search_input{
  width:45%;
  border-radius: 20px;
  padding-left:20px;
  background:transparent;
  border:1px solid rgb(65, 64, 64);
}
.footer-social-icon  .Submit_btn{
border-radius: 20px;
padding:15px;
margin-left:10px;
background:#D4B26B;
padding-left:15px;
padding-right:15px;
border:none;
}
.copyright-text{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.No1_Career_Cansulting_section_mobile_section .Mobile_section_counsoling{
  padding-left:10px;
  padding-right:10px;
  display:flex;
  align-items: center;
  justify-content: center;
}
.Images_section_mobile{
  height:100px;
  width:30%;
  display:flex;
  justify-content: center;
}
.TExt_section_mobile_view{
  height:100px;
  width:70%;
  display: flex;
  flex-direction: column;
}
.TExt_section_mobile_view h1{
  text-align: left;
  font-weight:800;
  padding-top: 20px;
  padding-bottom: 0;
  color:#D4B26B;
}
.TExt_section_mobile_view p{
  color:white;
}
/* blog section  */
.Blog_section_main{
  padding-left: 0px;
  padding-right:0px;
  height:1560px;
}
.Blog_section{
  width:100%;
  padding:0;
  margin:0;
  display:flex;
  justify-content: center;

  
}
.Blog_section .row{
  width:100%;
  border-radius: 8px;
  padding-bottom:30px;

}
.Blog_section_maion_div{
  padding:0;
  margin:0;
}

/* blog section pages */
.Bolg_section_deatials{
  background:#000000;
  padding-top:150px;
  padding-left:0px;
  padding-right:0px;
}
.Blog_search_filuter{
  display:flex;
  align-items: center;
  padding-left:0px;
  padding-right: 0px;
  background:black;
}
.Choose_pages_section{
  padding-top:20px;
  padding-left:0;
  padding-right:10px;
}
.Blog_section_main{
  background:black;
  padding-left: 0px;
  padding-right:0px;
  position:relative;
}
.Blog_section_text_main{
  padding-left:0px;
  padding-right:0px;
  padding-bottom: 15px;

}
/* details pages blog */
.Blog_section_section{
  background:#000000;
  padding-top:100px;
  padding-bottom:100px;
  padding-top:200px;
  padding-left:0px;
  padding-right:0px;

}
.Blog_section_text_section{
  background:black;
  padding-top:20px;
  padding-bottom:60px;
  padding-left:0px;
  padding-right:0px;
}

.Book_appoinents_sectyion_main {
  background-size:cover;
  background-repeat: no-repeat;
  padding-top:100px;
  height:720px;
  padding:10px;


}
.Book_appoinent_section{
  padding-bottom:0;
  margin-bottom: 0;
  position: relative;
  
}
.Book_appoinent_section div img{
  width:100%;
  height:100%;
  object-fit:cover ;
  padding-top:150px;
  position: relative;
}
.form_section_main{
  position: absolute;
  top:0;
  left:0;
  z-index: 9999;
  width:100%;
  padding-left:0px;
}
.form_section_main h1{
  color:white;
  font-size:32px;
  font-weight:500;
  font-family: 'Poppins', sans-serif;
  padding-top:150px;
}
.Number{
  background:white;
    border: none;
    border-bottom: solid 2px #DABD81;
    color:rgb(0, 0, 0);
    font-size: 1.000em;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0em 0 1.875em 0;
    width: 100%;
    line-height: 56px;
    -webkit-box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    padding-left:16px;
    outline: none;
    font-weight: 500;
    border-radius:8px;
}
.Date_section{
  background:white;
  border: none;
  border-bottom: solid 2px #DABD81;
  color: #111010;
  font-size: 1.000em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  outline: none;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 56px;
  border-radius:8px;
  font-weight: 500;
}
.Meassges{
  background:white;
  border: none;
  border-bottom: solid 2px #DABD81;
  color: B1B1B1;
  font-size: 1.000em;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  padding: 0 0 0.875em 0;
  height:112px;
  width: 100%;
  -webkit-box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-radius:8px;
  outline: none;
}
.Form_section button{
  width:100%;
  line-height:40px;
  background:#AF864E;
  border:none;
  border-radius: 5px;
  color:white;
  font-size: 16px;
  font-weight:600;
}
.Form_section{
  display:flex;
  flex-direction: column;
  column-gap: 24px;
}
.Input_section_date{
  display:flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  position: relative;
}
.Input_section_date .Date_img{
  width:48px;
  height:48px;
  padding-top:0;
  margin-bottom:25px;
  position: absolute;
  right:0px;
  object-fit: cover;
} 
}
@media only screen and (min-width: 1023px){
  .No1_Career_Cansulting_section_mobile_section{
    display: none;
  }
  .About_section_main_main{
    width:100%;
    padding-right:60px;
    padding-left:60px;
  }
  .About{
    background:#141414;
    border-radius: 16px;;
  }
  .BLog_section_main{
    background:black;
  }
  .Book_appoinents_sectyion_main {
    background:black;
    background-size:cover;
    background-repeat: no-repeat;
    padding-top:100px;
  
  }
  .Book_appoinent_section{
    padding-bottom:0;
    margin-bottom: 0;
    position: relative;
  }
  .Book_appoinent_section div img{
    width:100%;
    height:100%;
    object-fit:cover ;
    padding-top:150px;
    position: relative;
  }
  .form_section_main{
    position: absolute;
    top:0;
    left:0;
    z-index: 9999;
    width:100%;
  }
  .form_section_main h1{
    color:#565656;
    font-size:32px;
    font-weight:500;
    font-family: 'Poppins', sans-serif;
    padding-top:120px;
  }
  .Form_section{
    width:600px;
  }
  .Number{
    background:white;
    border: none;
    border-bottom: solid 2px #DABD81;
    color:rgb(0, 0, 0);
    font-size: 1.000em;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0em 0 1.875em 0;
    width: 100%;
    line-height: 56px;
    -webkit-box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    padding-left:16px;
    outline: none;
    font-weight: 500;
    border-radius:8px;
  }
  .Date_section{
    background:white;
    border: none;
    border-bottom: solid 2px #DABD81;
    color: #111010;
    font-size: 1.000em;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0em 0 1.875em 0;
    width: 100%;
    -webkit-box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    outline: none;
    box-sizing: border-box;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    line-height: 56px;
    border-radius:8px;
    font-weight: 500;
  }
  .Meassges{
    background:white;
    border: none;
    border-bottom: solid 2px #DABD81;
    color: B1B1B1;
    font-size: 1.000em;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 0em 0 1.875em 0;
    padding: 0 0 0.875em 0;
    height:112px;
    width: 100%;
    -webkit-box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-radius:8px;
    outline: none;
  }
  .Form_section button{
    width:100%;
    line-height:40px;
    background:#AF864E;
    border:none;
    border-radius: 5px;
    color:white;
    font-size: 16px;
    font-weight:600;
  }
  .Form_section{
    display:flex;
    flex-direction: column;
    column-gap: 24px;
  }
  .Input_section_date{
    display:flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    position: relative;
  }
  .Input_section_date .Date_img{
    width:48px;
    height:48px;
    padding-top:0;
    margin-bottom:25px;
    position: absolute;
    right:0px;
    object-fit: cover;
  } 
  
}
.Thank_section{
  display:flex;
  align-items: center;
  justify-content: center;
  padding-bottom:160px;
}
.Thank_section img{
  width:180px;
  padding-top:300px;
}
.Thank_section h1{
  padding:0;
  margin:0;
  color:white;
}